<template>
    <div>
        <v-container fluid class="pt-0 mt-5">
            <v-row>
                <v-col cols="12">
                    <v-alert :dismissible="false" class="red accent-4 white--text" dark border="left" icon="fab fa-buffer">
                        <v-row no-gutters class="align-center mt-n1 mb-n1">
                            <span>Redis Manager</span>
                            <v-spacer></v-spacer>
                            <!-- 查询 -->
                            <v-btn icon class="mr-5" @click="detail == 'search' ? detail = 'value' : detail ='search'" x-small>
                                <v-icon v-if="detail != 'search'">fas fa-search</v-icon>
                                <v-icon v-if="detail == 'search'">fas fa-times</v-icon>
                            </v-btn>
                            <!-- 添加 -->
                            <v-btn icon class="mr-5" @click="detail == 'add' ? detail = 'value' : detail ='add'" x-small>
                                <v-icon v-if="detail != 'add'">fas fa-plus</v-icon>
                                <v-icon v-if="detail == 'add'">fas fa-times</v-icon>
                            </v-btn>
                            <!-- 刷新 -->
                            <v-btn icon class="mr-3" @click="getKeys()" x-small>
                                <v-icon>fas fa-redo-alt</v-icon>
                            </v-btn>
                        </v-row>
                    </v-alert>
                </v-col>
            </v-row>
            <v-row>
                <!-- 左边 -->
                <v-col cols="5" class="leftFixedHeight pa-0 ma-0">
                    <v-row> 
                        <!-- 加载 -->
                        <v-col  v-if="list.length == 0 && message == null">
                            <v-progress-circular :size="30" color="red" indeterminate class="ml-9"></v-progress-circular>
                        </v-col>
                        <!-- 错误信息 -->
                        <v-col  v-if="message != null" >
                            <span class="ml-9">{{message}}</span>
                        </v-col>
                        <v-col v-if="list.length > 0">
                            <v-treeview dense hoverable return-object :active.sync="active" :open="open" :items="list" color="red accent-4" activatable item-text="name" item-key="id" item-children="children" open-on-click>
                                <template v-slot:prepend="{ item, open }">
                                    <v-icon v-if="item.children != null && item.children.length > 0">
                                        {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                                    </v-icon>
                                    <v-icon v-else small class="fas fa-key"></v-icon>
                                </template>
                                <template v-slot:label="{ item, open }" >
                                    <!-- 气泡提示 -->
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on">
                                                <!-- 树形Key的显示 -->
                                                {{ item.name }}{{ item.children != null && item.count > 0 ? (' (' + item.count + ')') : '' }}
                                            </span>
                                        </template>
                                        <span>{{ item.key }}</span>
                                    </v-tooltip>
                                </template>
                            </v-treeview>
                        </v-col>
                    </v-row>
                </v-col>
                <!-- 右边 -->
                <!-- 数据值 -->
                <v-col cols="7" v-if="detail == 'value' && active.length > 0" class="rightFixedHeight">
                    <div class="font-16">
                        <!-- key的条形显示 -->
                        <v-alert class="blue lighten-1" dark>
                            <v-row>
                                <v-icon small class="mr-3">fas fa-key</v-icon>
                                <!-- 复制 -->
                                <span id="key" class="pointer" @click="copyToClipboard('key')">{{ active[0].key }}</span>
                                <span v-if="redisValueExpire != null" class="ml-3">[Expire: {{ redisValueExpire + 's' }}]</span>
                                <!-- <v-btn icon class="mr-3 right" x-small @click="deleteRedis(active[0].key)">
                                    <v-icon>fas fa-trash-alt</v-icon>
                                </v-btn> -->
                                <v-spacer></v-spacer>
                                <!-- 编辑按钮 -->
                                <v-btn icon x-small class="mr-3" title="Edit Content" @click="editContent(active[0].key,redisValue,redisValueExpire)"><v-icon>fas fa-edit</v-icon></v-btn>
                                <!-- 删除 -->
                                <v-menu v-model="deleteMenu" offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon class="mr-3 right" x-small v-bind="attrs" v-on="on" title="Delete Key" @click="deleteMenu = true">
                                            <v-icon>fas fa-trash-alt</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-card>
                                        <v-card-title>
                                            <span>确定删除?</span>
                                        </v-card-title>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn text @click="deleteMenu = false">取消</v-btn>
                                            <v-btn color="red" text @click="deleteRedis(active[0].key)">删除</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-menu>
                            </v-row>
                        </v-alert>
                    </div>
                    <div>
                        <v-progress-circular :size="30" color="blue lighten-1" indeterminate class="ml-9" v-if="getLoading"></v-progress-circular>
                        <json-viewer :value="redisValue" :expand-depth="10" v-if="redisValue != null" copyable></json-viewer>
                    </div>
                </v-col>
                <!-- 添加 -->
                <v-col cols="7" v-if="detail == 'add'" class="text-right">
                    <div>
                        <v-form ref="form">
                            <v-text-field v-model="newRedis.key" label="Key" outlined placeholder="iobots:xxx"></v-text-field>
                            <v-text-field v-model="newRedis.time" label="Time" outlined type="Number"></v-text-field>
                            <v-textarea v-model="newRedis.value" label="Value" outlined></v-textarea>
                        </v-form>
                        <v-btn @click="detail = 'value'" class="mr-5" icon small>
                            <v-icon>fas fa-arrow-left</v-icon>
                        </v-btn>
                        <v-btn @click="addRedis" class="mr-5" icon small :loading="addLoading">
                            <v-icon>fas fa-check</v-icon>
                        </v-btn>
                    </div>
                </v-col>
                <!-- 编辑 -->
                <v-col cols="7" v-if="detail == 'edit'" class="text-right">
                    <div>
                        <v-form ref="form">
                            <v-text-field v-model="editObj.key" label="Key" outlined disabled></v-text-field>
                            <v-text-field v-model="editObj.time" label="Time" outlined type="Number"></v-text-field>
                            <v-textarea v-model="editObj.value" label="Value" outlined></v-textarea>
                        </v-form>
                        <v-btn @click="detail = 'value'" class="mr-5" icon small>
                            <v-icon>fas fa-arrow-left</v-icon>
                        </v-btn>
                        <v-btn @click="editRedis" class="mr-5" icon small :loading="addLoading">
                            <v-icon>fas fa-check</v-icon>
                        </v-btn>
                    </div>
                </v-col>
                <!-- 搜索 -->
                <v-col cols="7" v-if="detail == 'search'" class="rightFixedHeight">
                    <div class="font-16">
                        <!-- 搜索条条-->
                        <v-alert class="blue lighten-2my-0 py-0" dark>
                            <v-row dense>
                                <v-col cols="5">
                                    <v-row>
                                        <v-icon class="mr-3 ml-1" small dense>fas fa-key</v-icon>
                                        <v-text-field label="Key" outlined dense hide-details v-model="searchKey" @keyup.enter="getkey()" clearable></v-text-field> 
                                    </v-row>
                                </v-col>
                                <v-col cols="1">
                                    <v-btn icon class="ml-2">
                                        <v-icon small dense @click="getkey()">fas fa-search</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-alert>
                    </div>
                    <div v-for="(key,index) in redisKeys" :key="index" class="mt-3" :title="key" @click="[getValue(key)]">
                        <span class="pointer">{{key}}</span>
                    </div>
                    <div>
                        <v-progress-circular :size="30" color="blue lighten-1" indeterminate class="ml-9" v-if="getLoading"></v-progress-circular>
                        <json-viewer :value="redisValue" :expand-depth="10" v-if="redisValue != null" copyable></json-viewer>
                    </div>
                </v-col>
            </v-row>
        </v-container>        
    </div>
</template>

<script>
    import Vue from 'vue';
    import config from '../../config';
    import { mapGetters } from "vuex";
    // https://github.com/chenfengjw163/vue-json-viewer/blob/master/README_CN.md
    import JsonViewer from 'vue-json-viewer';
    export default {
        data() {
            return {
                detail : "value",
                active:[],
                open: [],
                list: [],
                message:null,
                redisValue:null,
                redisValueExpire: null,
                redisKeys:[],
                deleteMenu:false,
                editObj:{
                    key:null,
                    time:null,
                    value:null
                },
                newRedis:{
                    key:null,
                    time:null,
                    value:null
                },
                addLoading:false,
                deleteLoading:false,
                getLoading:false,
                searchKey:null,
            }
        },
        components:{
            JsonViewer,
        },
        computed: {
            ...mapGetters(['user']),
        },
        watch: {
            active(val){
                if(val[0] != null){
                    this.detail = 'value';
                    this.getValue(val[0].key);
                }
            },
            detail(val){
                if(val == 'value'){
                    if(this.active != null && this.active[0] != null && this.active[0].key){
                        // 不做任何操作
                    }else{
                        this.redisValue = null;
                    }
                }
            },
            searchKey(val){
                this.redisValue = null;
            }
        },
        mounted() {
            if (!this.user.token) {
                this.$router.push({ name: 'login' })
            };
        },
        created() {
           this.getKeys()
        },
        methods: {
            getKeys(){
                this.detail = 'value';
                this.list = [];
                this.active = [];
                this.message = null;
                this.redisValue = null;
                let api = config.baseUrl + "/admin/private/getRedisKeys";
                this.$http.get(api).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.list = res.data.result;
                    }else{
                        this.message = data.message;
                        this.$store.dispatch('snackbarMessageHandler', data.message);
                    }
                }, error => {
                    this.message = 'Internal Service Exception';
                    this.$store.dispatch('snackbarMessageHandler', 'Internal Service Exception');
                })
            },
            getkey(){
                if(this.searchKey != null){
                    this.redisValue = null;
                    let api = config.baseUrl + "/admin/private/getKeys?key=" + this.searchKey;
                    this.$http.get(api).then(res=>{
                        let data = res.data;
                        if(data.code == 200){
                            this.redisKeys = data.result;
                        }else{
                            this.redisKeys = [];
                            this.$store.dispatch('snackbarMessageHandler', data.message);
                        }
                    }, error => {
                        this.$store.dispatch('snackbarMessageHandler', 'Internal Service Exception');
                    })
                }
            },
            getValue(key){
                if(key == null){
                    return;
                }
                this.getLoading = true;
                this.redisValue = null;
                this.redisValueExpire = null;
                // this.detail = 'value';
                let api = config.baseUrl + "/admin/private/getRedisValue?key=" + key;
                this.$http.get(api).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.redisValue = data.result.data;
                        this.redisValueExpire = data.result.expire;
                        this.getLoading = false;
                    } else {
                        this.$store.dispatch('snackbarMessageHandler', data.message);
                        this.getLoading = false;
                    }
                }, error => {
                    this.$store.dispatch('snackbarMessageHandler', 'Internal Service Exception');
                    this.getLoading = false;
                })
            },
            addRedis(){
                this.addLoading = true;
                let api = config.baseUrl + "/admin/private/addRedis";
                this.$http.post(api,this.newRedis).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.$store.dispatch('snackbarMessageHandler', data.message);
                        this.getKeys();
                        this.detail ="value";
                        this.$refs.form.reset();
                        this.addLoading = false;
                    }else{
                        this.$store.dispatch('snackbarMessageHandler', data.message);
                        this.addLoading = false;
                    }
                }, error => {
                    this.$store.dispatch('snackbarMessageHandler', 'Internal Service Exception');
                    this.addLoading = false;
                })
            },
            deleteRedis(key){
                this.deleteLoading = true;
                let api = config.baseUrl + "/admin/private/deleteRedis?key=" + key;
                this.$http.delete(api).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.$store.dispatch('snackbarMessageHandler', data.message);
                        this.getKeys();
                        this.deleteLoading = false;
                    }else{
                        this.$store.dispatch('snackbarMessageHandler', data.message);
                        this.deleteLoading = false;
                    }
                }, error => {
                    this.$store.dispatch('snackbarMessageHandler', 'Internal Service Exception');
                    this.deleteLoading = false;
                });
            },
            // 编辑redis
            editContent(key,val,time){
                this.detail = 'edit';
                this.editObj = {
                    key:JSON.parse(JSON.stringify(key)),
                    value:JSON.stringify(val),
                    time:JSON.parse(JSON.stringify(time)),
                }
            },
            editRedis(){
                this.deleteLoading = true;
                let api = config.baseUrl + "/admin/private/editRedis";
                this.$http.post(api,this.editObj).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.$store.dispatch('snackbarMessageHandler', data.message);
                        // this.getKeys();
                        this.detail ="value";
						this.getValue(this.active[0].key);
                        this.$refs.form.reset();
                        this.addLoading = false;
                    }else{
                        this.$store.dispatch('snackbarMessageHandler', data.message);
                        this.addLoading = false;
                    }
                }, error => {
                    this.$store.dispatch('snackbarMessageHandler', 'Internal Service Exception');
                    this.addLoading = false;
                })
            },
            // 复制到剪切板
            copyToClipboard(id) {
                let target = document.querySelector('#' + id);
                try {
                  let range = document.createRange();
                  // 选择对象
                  range.selectNode(target);
                  window.getSelection().removeAllRanges();
                  window.getSelection().addRange(range);
                  // 执行浏览器复制命令
                  document.execCommand('copy');
                  window.getSelection().removeAllRanges();
                  // 复制成功
                  this.$store.dispatch('snackbarMessageHandler', 'copied');
                } catch (e) {
                  // 复制失败
                  this.$store.dispatch('snackbarMessageHandler', 'Copy Failed');
                }
            },
        }
    }
</script>

<style>

</style>